import { useState } from 'react';
import './App.css';
import { Form, Button } from 'react-bootstrap';
import instance from './api/api';

enum EMethod {
    CLONE = "clone",
    DELETE = "delete",
}

function App() {
    const [identifier, setIdentifier] = useState<string>("");
    const [tag, setTag] = useState<string>("");
    const [method, setMethod] = useState<string>(EMethod.CLONE);

    const methodOptions = [
        { value: "clone", label: "Clone" },
        { value: "delete", label: "Delete" },
    ]

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        try {
            if (method === EMethod.CLONE) {
                const data = { identifier: identifier, tag: tag};
                await instance.post("/clone", data);
            } else if (method === EMethod.DELETE) {
                const data = { identifier: identifier};
                await instance.post("/delete", data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="App p-5">
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Label>
                        Identifier
                    </Form.Label>
                    <Form.Control value={identifier} onChange={(e) => setIdentifier(e.target.value)} required type="text"/>
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label>
                        Tag
                    </Form.Label>
                    <Form.Control value={tag} onChange={(e) => setTag(e.target.value)} required={method === EMethod.CLONE} type="text"/>
                </Form.Group>

                <Form.Group className="mt-3">
                    <Form.Label>
                        Method
                    </Form.Label>
                    <Form.Select required value={method} onChange={(e) => setMethod(e.target.value)}>
                        {methodOptions.map(method => {
                            return <option value={method.value}>{method.label}</option>
                        })}
                    </Form.Select>
                </Form.Group>

                <Button type="submit" className="mt-5">Submit</Button>
            </Form>
        </div>
    );
}

export default App;
